import Header from "../components/Header";
import HeroSection from "../components/HeroSection";
import AboutSection from "../components/AboutSection";
import Features from "../components/FeaturesSection";
import CtaSection from "../components/CtaSection";
import Footer from "../components/Footer";
import { aboutData } from "../data";

const Home = () => {
  return (
    <>
      <Header />
      <HeroSection />
      <AboutSection aboutData = {aboutData}/>
      <Features />
      <CtaSection />
      <Footer />
    </>
  );
};
export default Home;
