import React from 'react';
import { Button, Flex, Text } from '@chakra-ui/react';

const PaginationComp = ({ currentPage, totalCount, pageSize, onPageChange }) => {
  const totalPages = Math.ceil(totalCount / pageSize);

  const changePage = (page) => {
    onPageChange(Math.min(Math.max(1, page), totalPages));
  };

  return (
    <Flex align="center" justify="space-evenly" m={4}>
      <Button
        onClick={() => changePage(currentPage - 1)}
        disabled={currentPage <= 1}
        backgroundColor={'gray.100'}
      >
        Previous
      </Button>
      <Text>Page {currentPage} of {totalPages}</Text>
      <Button
        onClick={() => changePage(currentPage + 1)}
        disabled={currentPage >= totalPages}
        backgroundColor={'gray.100'}
      >
        Next
      </Button>
    </Flex>
  );
};

export default PaginationComp