import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// Import necessary components from Chakra UI, if you're using it
import {
  Container,
  Flex,
  Text,
  Divider,
  Image,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Heading,
  Box,
  List,
  ListItem,
  ListIcon,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  SimpleGrid,
} from "@chakra-ui/react";
import { GiMedicalDrip, GiMedicalThermometer } from "react-icons/gi";
import { LiaFileMedicalAltSolid } from "react-icons/lia";
import Noimage from "../images/noimage.png";
import colorMappings from "../data/colorMapping";
import { ChevronRightIcon } from "@chakra-ui/icons";
import QRCode from "react-qr-code";
import { useLocation } from "react-router-dom";

function isLight(color) {
  // Variables for red, green, blue values
  let r, g, b, hsp;
  const colors = colorMappings;

  color = colors[color.toLowerCase()] || color;
  // Check the format of the color, HEX or RGB?
  if (color.match(/^rgb/)) {
    // If RGB --> store the red, green, blue values in separate variables
    color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*\d+\.\d+)?\)$/);

    r = color[1];
    g = color[2];
    b = color[3];
  } else {
    // If hex --> Convert it to RGB: http://gist.github.com/983661
    color = +("0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&"));

    r = color >> 16;
    g = (color >> 8) & 255;
    b = color & 255;
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Using the HSP value, determine whether the color is light or dark
  return hsp > 127.5;
}

const ProductDetailsSection = () => {
  const [productDetails, setProductDetails] = useState(null);
  const { productId } = useParams(); // This captures the productId from the URL

  useEffect(() => {
    const headers = {
      Authorization:
        "Bearer patlLsT9MObSTGoEb.d64db7431433de9e135f5a5b9de42d144c4f0716c2c5d73588adde643d216f00",
    };
    fetch(
      `https://api.airtable.com/v0/appsG3Y5K52iboL4x/MasterData/${productId}`,
      { headers }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setProductDetails(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [productId]);
  const [selectedImage, setSelectedImage] = useState(
    productDetails?.fields?.image?.url
  );
  useEffect(() => {
    setSelectedImage(productDetails?.fields?.image[0]?.url);
  }, [productDetails?.fields?.image]);

  const location = useLocation();
  const [currentUrl, setCurrentUrl] = useState(window.location.href);

  useEffect(() => {
    setCurrentUrl(window.location.href);
  }, [location]); // Dependency on location ensures effect runs on location change

  return (
    <>
      <section className="lg:h-[auto] py-12">
        <Container
          minH={["100vh", "88vh", "98vh", "98vh", "72vh"]}
          maxW="container.xl"
        >
          <Divider mt="6" mb="6" borderColor="#E3E3E3" />
          <Breadcrumb
            spacing="8px"
            separator={<ChevronRightIcon color="gray.500" />}
          >
            <BreadcrumbItem>
              <BreadcrumbLink href="/#">Home</BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem>
              <BreadcrumbLink href="/products">Products</BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem isCurrentPage>
              <BreadcrumbLink href="#">
                {productDetails?.fields?.name}
              </BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
          <Divider mt="6" mb="6" borderColor="white" />
          <Flex
            h={["auto", "auto", "auto", "auto", "80vh"]}
            pr={["1", "3", "4", "8", "0"]}
            pl={["1", "3", "4", "8", "0"]}
            flexDir={["column", "column", "column", "column", "row-reverse"]}
          >
            <Flex
              ml={["0", "0", "0", "0", "20"]}
              mt={["45px", "40px", "50px", "70px", "0px"]}
              flex={1}
            >
              <Flex
                justifyContent="center"
                alignItems="center"
                p="4"
                w="full"
                rounded="md"
                flexDir={"column"}
              >
                <Image
                  objectFit="contain"
                  w="500px"
                  h="100%"
                  maxWidth="100%"
                  maxHeight="100%"
                  src={selectedImage ?? Noimage}

                />
                <SimpleGrid
                  columns={productDetails?.fields?.image?.length}
                  spacing={4}
                >
                  {productDetails?.fields?.image?.map((image, index) => (
                    <Image
                      key={index}
                      src={image.url}
                      alt={`Thumbnail ${index + 1}`}
                      w="50px"
                      h="50px"
                      objectFit="cover"
                      rounded="md"
                      onClick={() => setSelectedImage(image.url)}
                      _hover={{
                        cursor: "pointer",
                        opacity: "0.8",
                      }}
                    />
                  ))}
                </SimpleGrid>
                <Divider mt="6" mb="6" borderColor="white" />
                {productDetails?.fields?.color?.length > 0 && (
                  <>
                    <Heading as="h4" size="md">
                      Available Colors
                    </Heading>

                    <Flex mt="4" justifyContent="center">
                      {productDetails?.fields?.color?.map((color, index) => {
                        const textColor = isLight(color) ? "black" : "white";
                        return (
                          <Flex
                            key={index}
                            justifyContent="center"
                            alignItems="center"
                            h="55px" // Height of the circle
                            w="55px" // Width of the circle
                            borderRadius="full" // Makes it a circle
                            bg={color} // Background color of the circle
                            mx="3" // Margin on left and right for spacing
                            border="2px"
                            borderColor="gray.200"
                          >
                            <Text color={textColor} fontSize="sm">
                              {color}
                            </Text>
                          </Flex>
                        );
                      })}
                    </Flex>
                  </>
                )}
              </Flex>
            </Flex>

            <Flex flex={1} flexDir="column">
              <Text
                mt="3"
                fontSize="32px"
                fontWeight={"black"}
                alignSelf={"end"}
                fontFamily={"monospace"}
              >
                {productDetails?.fields?.catalogCode}
              </Text>
              <Flex flexDir="row" justifyContent="space-between">
                <Text fontSize="36px" fontWeight="bold">
                  {productDetails?.fields?.name}
                </Text>
              </Flex>
              <Text fontSize="21px" mt="23px">
                {productDetails?.fields?.Desc}
              </Text>

              <Divider mt="4" mb="4" borderColor="#E3E3E3" />
              <Heading as="h4" size="md">
                Features
              </Heading>
              <List spacing={6} mt={6}>
                <ListItem>
                  <ListIcon
                    as={GiMedicalDrip}
                    boxSize={10}
                    color="purple.600"
                  />
                  {productDetails?.fields?.Feature1}
                </ListItem>
                <ListItem>
                  <ListIcon
                    as={GiMedicalThermometer}
                    boxSize={10}
                    color="purple.700"
                  />
                  {productDetails?.fields?.Feature2}
                </ListItem>
                <ListItem>
                  <ListIcon
                    as={LiaFileMedicalAltSolid}
                    boxSize={10}
                    color="purple.700"
                  />
                  {productDetails?.fields?.Feature3}
                </ListItem>
              </List>
              <Divider mt="8" mb="8" borderColor="#E3E3E3" />
              <Heading as="h4" size="md">
                Available Size
              </Heading>
              <Box
                bg="#f5f5f5"
                w="100%"
                p={4}
                mt="5"
                borderRadius="15"
                boxShadow="xl"
              >
                <TableContainer>
                  <Table variant="simple">
                    <Thead>
                      <Tr>
                        <Th>Size</Th>
                        {/* <Th isNumeric>Size</Th> */}
                      </Tr>
                    </Thead>
                    <Tbody>
                      {productDetails?.fields?.size?.map((size) => {
                        return (
                          <Tr>
                            <Td>{size}</Td>
                            {/* <Td isNumeric>{size}</Td> */}
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </Table>
                </TableContainer>
              </Box>
            </Flex>
          </Flex>
          <Divider mt="8" mb="8" borderColor="#fff" />
          <Flex mt="4" justifyContent="center">
            <QRCode
              value={currentUrl}
              size={128}
              style={{ height: "auto", maxWidth: "128", width: "128" }}
            />
          </Flex>
        </Container>
      </section>
      <Divider mt="8" mb="8" borderColor="#E3E3E3" />
    </>
  );
};

export default ProductDetailsSection;
