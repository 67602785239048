import React, { useEffect, useState } from "react";
import {
  Flex,
  Text,
  Divider,
  Image,
  Center,
  Button,
  Card,
  AbsoluteCenter,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { Spinner } from "@chakra-ui/react";
import Noimage from "../images/noimage.png";
import { useNavigate } from "react-router-dom";
import PaginationComp from "./PaginationComp";

const ProductsList = ({ category }) => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [products, setProducts] = useState([]);
  const pageSize = 9; // Set the number of products per page
  const [totalCount, setTotalCount] = useState(0); // Total number of products
  const [, setFilteredRecords] = useState([]);

  console.log(category);
  useEffect(() => {
    const fetchData = async () => {
      const headers = {
        Authorization:
          "Bearer patlLsT9MObSTGoEb.d64db7431433de9e135f5a5b9de42d144c4f0716c2c5d73588adde643d216f00",
      };
      try {
        const response = await fetch(
          "https://api.airtable.com/v0/appsG3Y5K52iboL4x/MasterData",
          { headers }
        );
        const data = await response.json();
        const filteredData = data.records.filter(
          (record) => record.fields && record.fields.Category === category
        ); // Fixed typo and used strict equality
        setFilteredRecords(filteredData);
        setProducts(filteredData);
        setTotalCount(filteredData.length);
        console.log(data.records.length); // Correctly log the total records length
        console.log(filteredData); // Log the filtered records length
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [currentPage, category]); // Ensure these are the correct dependencies

  const onPageChange = (page) => setCurrentPage(page);
  const indexOfLastProduct = currentPage * pageSize;
  const currentProducts = products.slice(
    indexOfLastProduct - pageSize,
    indexOfLastProduct
  );

  return (
    <>
      <Flex
        justifyContent={[
          "center",
          "center",
          "center",
          "center",
          "left",
        ]}
        flexWrap="wrap"
      >
        {currentProducts.length > 0 ? (
          currentProducts.map((filteredData) => (
            <Card
              mb="14"
              key={filteredData.fields.id}
              flexDir="column"
              p="0"
              m="4"
              rounded="22px"
              w="365px"
              h="400"
              boxShadow="base"
              justifyContent={"space-between"}
              border={"1px"}
              borderColor={"gray.200"}
            >
              <Flex
                w="full"
                h="236px"
                rounded="22px"
                p="4"
                justifyContent="center"
                alignItems="center"
                bg={filteredData.fields.bg}
                overflow="hidden"
              >
                <Image
                  w="270px"
                  h="100%" // Add this line to maintain aspect ratio within container height
                  maxWidth="100%" // Add this line to ensure width does not exceed the container
                  maxHeight="100%" // Add this line to ensure height does not exceed the container
                  objectFit="contain"
                  src={filteredData.fields.image?.[0]?.thumbnails?.large?.url}
                  fallbackSrc={Noimage}
                />
              </Flex>
              <Link to={{ pathname: `/products/${filteredData.id}` }}>
                <Flex p="4" justifyContent="space-between">
                  <Flex flexDir="column">
                    <Text fontWeight="700" fontSize="23px">
                      {filteredData.fields.name}
                    </Text>
                    {/* <Text
                          w="230px"
                          fontSize="13px"
                          mt="-2px"
                          color="#7E7E7E"
                          overflow="hidden"
                          whiteSpace="nowrap"
                          textOverflow="ellipsis"
                        >
                          {record.fields.Desc}
                        </Text> */}
                    <Text>{filteredData.fields.catalogCode}</Text>
                  </Flex>
                </Flex>
              </Link>
              <Center>
                <Button
                  fontSize="14px"
                  border="1px solid"
                  borderColor="#191919"
                  rounded="8"
                  h="30px"
                  w="90%"
                  variant="outline"
                  // _hover={{ bg:record.fields.bg}}
                  onClick={() => navigate(`/products/${filteredData.id}`)} // Adjust this line
                  mb={5}
                >
                  More Details
                </Button>
              </Center>
            </Card>
          ))
        ) : (
          <AbsoluteCenter>
            <Spinner
              size="xl"
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
            />
          </AbsoluteCenter>
        )}
      </Flex>
      <PaginationComp
        currentPage={currentPage}
        totalCount={totalCount}
        pageSize={pageSize}
        onPageChange={onPageChange}
      />
      <Divider mt="8" mb="8" borderColor="#E3E3E3" />
    </>
  );
};

export default ProductsList;
