import React, { useEffect, useState } from "react";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Container,
  Flex,
  Divider,
  Text,
} from "@chakra-ui/react";
import ProductsList from "./ProductsList"; // Adjust the import path as necessary

const TabComp = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      const headers = {
        Authorization:
          "Bearer patlLsT9MObSTGoEb.d64db7431433de9e135f5a5b9de42d144c4f0716c2c5d73588adde643d216f00",
      };
      try {
        const response = await fetch(
          "https://api.airtable.com/v0/appsG3Y5K52iboL4x/MasterData",
          { headers }
        );
        const data = await response.json();
        console.log(data);
        const allCategories = data.records.map(
          (record) => record.fields.Category
        );
        const filteredCategories = allCategories.filter(
          (category) => category !== undefined
        );
        const uniqueCategories = [...new Set(filteredCategories)];
        setCategories(uniqueCategories);
        console.log(uniqueCategories);
      } catch (error) {
        console.error(error);
      }
    };
    fetchCategories();
  }, []);

  return (
    <section className="lg:h-[auto] py-12">
      <Container maxW="container.xl" style={{ marginTop: 0 }}>
        {/* TODO remove the following into a seperate comp */}
        <Flex mt="10" w="full" justifyContent="space-between">
          <Flex flexDir="column">
            <Text
              fontSize={["27px", "27px", "35px", "35px", "35px"]}
              fontWeight="600"
              color="#191919"
            >
              Wide Veriety of Products
            </Text>
            <Text
              fontSize={["14px", "15px", "17px", "17px", "17px"]}
              mt="-4px"
              color="#7E7E7E"
            >
              If you don't find what you are looking for, feel free to reach
              out, we still have more
            </Text>
          </Flex>
        </Flex>
        <Divider mt="8" mb="8" borderColor="#E3E3E3" />
        {/* TODO remove untill here */}
        <Tabs variant="soft-rounded" colorScheme="purple" isLazy>
          <TabList style={{ overflowX: 'auto', scrollbarWidth: 'none' }}>
           {categories.map((category, index) => (
              <Tab sx={{ whiteSpace: 'nowrap'}} key={index}>{category}</Tab>  
            ))}
          </TabList>
          <TabPanels>
            {categories.map((category, index) => (
              <TabPanel key={index}>
                <ProductsList category={category} />
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </Container>
    </section>
  );
};

export default TabComp;
