// import icons
import { FaInstagram, FaGithub, FaLinkedinIn } from 'react-icons/fa';
import { BsChatDotsFill } from 'react-icons/bs';

// import images
import AboutImg from '../src/assets/img/about.png';
import Feature1Img from '../src/assets/img/features/feature1.png';
import Feature2Img from '../src/assets/img/features/feature2.png';
import Avatar1Img from '../src/assets/img/testimonials/avatar1.png';
import Avatar2Img from '../src/assets/img/testimonials/avatar2.png';
import Avatar3Img from '../src/assets/img/testimonials/avatar3.png';
import LogoV2 from '../src/assets/img/logo-v2.png';
import HeroImage from '../src/assets/img/hero.png';
import Feature1BgImg from '../src/assets/img/features/feature1_bg.png';
import Feature2BgImg from '../src/assets/img/features/feature2_bg.png';


export const navigationData = [
  // {
  //   name: 'Deliver',
  //   href: '#',
  // },
  {
    name: 'About',
    href: '/about',
  },
  {
    name: 'Products',
    href: '/products',
  },
  {
    name: 'Contact Us',
    href: '/contact',
  },
];

export const heroData = {
  title: ` Products you can trust`,
  subtitle:
    "Our team has been leading the medical supply business for over twenty years. We are trusted suppliers of all your medical industry needs. We've got you covered.",
  btnText: 'Products List',
  image: HeroImage,
  btnPath: 'products'
};

export const aboutData = {
  image: AboutImg,
  title: 'Helping you do your job safely & Efficiently ',
  subtitle:
    'Our company provides wide range of FDA Certified products ranging from disposable single use items to hospital beds',
};

export const aboutUsData = {
  image: AboutImg,
  title: 'A Medical Supply Company you can trust',
  subtitle:
    'We lead with transparency, collaboration and accessibility. These three core values is what drive our success and long-lasting client relationships. We are more than just a supplier, we are your long-term business partner, helping you grow and achieve your long term goals. We see this as a long-lasting partnership',
};

export const featuresData = {
  title: 'Bridging the gap',
  subtitle:
    'Our approach is simple. We work everyday to connect healthcare workers with the best medical supply equipments the healthcare system needs to deliver the best care  ',
  list: [
    {
      image: Feature1Img,
      bgImage: Feature1BgImg,
      title: 'Team',
      description:
        'Learn about Acro team',
      linkText: 'Learn more',
      delay: '400',
      linkPath: '/about'
    },
    {
      image: Feature2Img,
      bgImage: Feature2BgImg,
      title: 'Find Your Product',
      description:
        'We a wide range of products',
      linkText: 'Learn more',
      delay: '500',
      linkPath: '/products'
    },
    // {
    //   image: Feature3Img,
    //   bgImage: Feature3BgImg,
    //   title: 'Print Out',
    //   description:
    //     'Print out service gives you convenience if someday you need print data, just edit it all and just print it.',
    //   linkText: 'Learn more',
    //   delay: '1000',
    // },
    // {
    //   image: Feature4Img,
    //   bgImage: Feature4BgImg,
    //   title: 'Product Received',
    //   description:
    //     'In our app you can see the delay time of your order...',
    //   linkText: 'Learn more',
    //   delay: '1300',
    // },
  ],
};

export const testimonialsData = [
  {
    image: Avatar1Img,
    name: 'Serena',
    web: 'rena.com',
    message:
      'Suspendisse ultrices at diam lectus nullam. Nisl, sagittis viverra enim erat tortor ultricies massa turpis. Arcu pulvinar aenean nam laoreet nulla.',
    delay: '300',
  },
  {
    image: Avatar2Img,
    name: 'Natalia',
    web: 'nataliya.com',
    message:
      'Suspendisse ultrices at diam lectus nullam. Nisl, sagittis viverra enim erat tortor ultricies massa turpis. Arcu pulvinar aenean nam laoreet nulla.',
    delay: '600',
  },
  {
    image: Avatar3Img,
    name: 'Vebin',
    web: 'vebin.com',
    message:
      'Suspendisse ultrices at diam lectus nullam. Nisl, sagittis viverra enim erat tortor ultricies massa turpis. Arcu pulvinar aenean nam laoreet nulla.',
    delay: '900',
  },
];

export const ctaData = {
  title: 'Contact Us',
  subtitle: 'Discover how we can assist your business in enhancing product offerings and alleviating supply chain challenges.',
  btnText1: 'Learn more',
  btnText2: 'Request Demo',
};

export const footerData = {
  logo: LogoV2,
  address: '8 The Green Dover, DE 19901',
  email: 'info@acrollc.com',
  phone: '1-(276)-800-0030',
  list1: [
    {
      name: 'Home',
      href: '/#',
    },
    {
      name: 'Products',
      href: '#',
    },
    {
      name: 'Careers',
      href: '#',
    },
    {
      name: 'News',
      href: '#',
    },
    {
      name: 'Support',
      href: '#',
    },
  ],
  list2: [
    {
      name: 'Sign Up',
      href: '#',
    },
    {
      name: 'Guide',
      href: '#',
    },
    {
      name: 'Reports',
      href: '#',
    },
    {
      name: 'Q & A',
      href: '#',
    },
  ],
  socialList: [
    {
      icon: <FaLinkedinIn/>,
      href: '#',
    },
    {
      icon: <FaInstagram />,
      href: '#',
    },
    {
      icon: <FaGithub />,
      href: '#',
    },
  ],
};

export const copyrightData = {
  text: '© ACRO LLC, 2024. All rights reserved.',
  icon: <BsChatDotsFill />,
};
