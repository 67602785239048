import { Container } from "@chakra-ui/layout";

const Layout = ({ children }) => {
  return (
    <Container maxW="100vw" p="0" minH="85vh" mt={10} mb={10}>
      {children}
    </Container>
  );
};

export default Layout;
