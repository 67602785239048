import Header from "../components/Header";
import Footer from "../components/Footer";
import ProductDetailsSection from "../components/ProductDetailsSection";

const ProductDetails = () => {
  return (
    <>
      <Header />
      <ProductDetailsSection />
      <Footer />
    </>
  );
};
export default ProductDetails;
