import Header from "../components/Header";
import Footer from "../components/Footer";
import AboutSection from "../components/AboutSection";
import { aboutUsData } from '../data';
import Layout from "../components/Layout";

const About = () => {
  return (
    <>
      <Header />
      <Layout>
        <AboutSection aboutData = {aboutUsData}></AboutSection>
      </Layout>
      <Footer />
    </>
  );
};
export default About;
