import Header from "../components/Header";
import Footer from "../components/Footer";
// import ProductsList from "../components/ProductsList";
import Layout from "../components/Layout";
import TabComp from "../components/TabComp";
const Products = () => {
  return (
    <>
      <Header />
      <Layout>
        <TabComp/>
      {/* <ProductsList /> */}
      </Layout>
      <Footer />
    </>
  );
};
export default Products;
