import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';



// import animate on scroll
import Aos from 'aos';
import 'aos/dist/aos.css';
// import components

import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme";
import Home from './pages/Home'
import Products from './pages/Products';
import About from './pages/About'
import ContactUs from './pages/ContactUs'
import ProductDetails from './pages/ProductDetails';

const App = () => {
  // animate on scroll initialization
  Aos.init({
    duration: 1800,
    offset: 0,
  });

  return (
    <ChakraProvider theme={theme}>

    <Router>
      <div className='overflow-hidden'>
        <Routes>
          <Route path="/" element={<><Home /></>} />
          <Route path="/products" element={<Products />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/products/:productId" element={<ProductDetails />} />
        </Routes>
      </div>
    </Router>
  </ChakraProvider>
  );
};

export default App;
